import type { Lot } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "./program_wrapper";
import {
  hasSomeVatIncGrid as hasSomeVatIncGrids,
  isDateDisplayPricesPast,
} from "./helper_program";
import {
  getPricesFieldNamesForRegulationTids,
  getSlice,
  hasRegulation,
  hasSomeRegulations,
} from "./helper_slice";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { formatRate, formatPrice, formatSurface } from "./format";
import type { PriceField } from "~/types/common";

export type PriceType =
  | "vat_inc"
  | "vat_inc_reduced"
  | "vat_inc_brs"
  | "vat_inc_mastered"
  | "bare_ownership"
  | "lmnp_vat_ex"
  | "lli"; /* LLI TMP refs #9179 */
/*
  | "patrimonial"
  | "lmnp_vat_inc"
*/

type PriceFieldDataTest =
  | "price-vat-inc"
  | "price-vat-inc-reduced"
  | "price-vat-inc-brs"
  | "price-vat-inc-mastered"
  | "price-vat-ex";

type PriceGroup = {
  data_test: PriceFieldDataTest;
  price_label: string;
  price: string;
  price_raw: number | undefined;
  return_rates: ReturnRate[];
};

type ReturnRate = {
  label: string;
  rate: string;
  data_test: "return-rate-vat-ex" | "return-rate-vat-inc";
  rate_raw: number | undefined;
};

export type PricesAndReturnRates = Record<PriceType, PriceGroup | undefined>;

export function getPricesAndReturnRates(
  wrapper: ProgramWrapper,
  lot: Lot,
): PricesAndReturnRates {
  const settings = useDrupalSettingsContext();
  const slice = getSlice(lot.slice_id.meta.drupal_internal__target_id, wrapper);
  const displayPrices = isDateDisplayPricesPast(wrapper);

  const showReturnRate = wrapper.program.field_disp_return_rate;

  const data: Record<PriceType, PriceGroup | undefined> = {
    vat_inc: undefined,
    vat_inc_reduced: undefined,
    vat_inc_brs: undefined,
    vat_inc_mastered: undefined,
    bare_ownership: undefined,
    lmnp_vat_ex: undefined,
    lli: undefined /* LLI TMP refs #9179 */,
  };

  let regulations;
  let return_rates: ReturnRate[] = [];

  //////////////////////////////////////////////////////////////////////////////
  // vat_inc
  regulations = [
    settings.regulations_tids.tva_normale,
    settings.regulations_tids.patrimonial,
    settings.regulations_tids.lmnp_vat_inc,
  ];

  if (
    slice &&
    hasSomeVatIncGrids(wrapper) &&
    hasSomeRegulations(slice, regulations) &&
    Number(getPriceIncludingChildren(lot, "price_vat_inc", wrapper)) > 0
  ) {
    // Rentabilité marché
    if (
      lot.return_rate_vat_inc &&
      wrapper.program.field_disp_grid_patrimonial &&
      hasRegulation(slice, settings.regulations_tids.patrimonial)
    ) {
      showReturnRate &&
        return_rates.push({
          label: "Marché",
          rate: `${formatRate(lot.return_rate_vat_inc)} / an`,
          data_test: "return-rate-vat-inc",
          rate_raw: lot.return_rate_vat_inc,
        });
    }

    data.vat_inc = {
      data_test: "price-vat-inc",
      price_label: "TVA 20 %",
      price: displayPrices
        ? formatPrice(getPriceIncludingChildren(lot, "price_vat_inc", wrapper))
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_inc", wrapper)
        : undefined,
      return_rates: return_rates,
    };
  }

  //////////////////////////////////////////////////////////////////////////////
  // vat_inc_reduced
  regulations = [settings.regulations_tids.tva_reduite];

  if (
    slice &&
    wrapper.program.field_disp_grid_vat_inc_reduced &&
    hasSomeRegulations(slice, regulations) &&
    getPriceIncludingChildren(lot, "price_vat_inc_reduced", wrapper) > 0
  ) {
    return_rates = [];

    data.vat_inc_reduced = {
      data_test: "price-vat-inc-reduced",
      price_label: `TVA ${formatRate(lot.vat_rate!)}`,
      price: displayPrices
        ? formatPrice(
            getPriceIncludingChildren(lot, "price_vat_inc_reduced", wrapper),
          )
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_inc_reduced", wrapper)
        : undefined,
      return_rates: return_rates,
    };
  }

  //////////////////////////////////////////////////////////////////////////////
  // brs
  regulations = [settings.regulations_tids.brs];

  if (
    slice &&
    wrapper.program.field_disp_grid_vat_inc_brs &&
    hasSomeRegulations(slice, regulations) &&
    getPriceIncludingChildren(lot, "price_vat_inc_brs", wrapper) > 0
  ) {
    return_rates = [];

    data.vat_inc_brs = {
      data_test: "price-vat-inc-brs",
      price_label: `Prix BRS`,
      price: displayPrices
        ? formatPrice(
            getPriceIncludingChildren(lot, "price_vat_inc_brs", wrapper),
          )
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_inc_brs", wrapper)
        : undefined,
      return_rates: return_rates,
    };
  }

  //////////////////////////////////////////////////////////////////////////////
  // vat_inc_price_mastered
  regulations = [settings.regulations_tids.prix_maitrises];

  if (
    slice &&
    wrapper.program.field_disp_grid_vat_inc_mastered &&
    hasSomeRegulations(slice, regulations) &&
    getPriceIncludingChildren(lot, "price_vat_inc_mastered", wrapper) > 0
  ) {
    return_rates = [];

    data.vat_inc_mastered = {
      data_test: "price-vat-inc-mastered",
      price_label: `Prix maîtrisé`,
      price: displayPrices
        ? formatPrice(
            getPriceIncludingChildren(lot, "price_vat_inc_mastered", wrapper),
          )
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_inc_mastered", wrapper)
        : undefined,
      return_rates: return_rates,
    };
  }

  //////////////////////////////////////////////////////////////////////////////
  // bare_ownership
  regulations = [settings.regulations_tids.nue_propriete];

  if (
    slice &&
    wrapper.program.field_disp_grid_bare_ownership &&
    hasSomeRegulations(slice, regulations) &&
    getPriceIncludingChildren(lot, "price_vat_inc_reduced", wrapper) > 0
  ) {
    return_rates = [];

    data.bare_ownership = {
      data_test: "price-vat-inc-reduced",
      price_label: "TVA 10 %",
      price: displayPrices
        ? formatPrice(
            getPriceIncludingChildren(lot, "price_vat_inc_reduced", wrapper),
          )
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_inc_reduced", wrapper)
        : undefined,
      return_rates: return_rates,
    };
  }

  //////////////////////////////////////////////////////////////////////////////
  // lmnp_vat_ex
  regulations = [settings.regulations_tids.lmnp_vat_ex];

  if (
    slice &&
    wrapper.program.field_disp_grid_lmnp_vat_ex &&
    hasSomeRegulations(slice, regulations) &&
    getPriceIncludingChildren(lot, "price_vat_ex", wrapper) > 0
  ) {
    return_rates = [];
    if (lot.return_rate_vat_ex) {
      showReturnRate &&
        return_rates.push({
          label: "LMNP géré",
          rate: `${formatRate(lot.return_rate_vat_ex)} / an`,
          data_test: "return-rate-vat-ex",
          rate_raw: lot.return_rate_vat_ex,
        });
    }

    data.lmnp_vat_ex = {
      data_test: "price-vat-ex",
      price_label: `Prix H.T.`,
      price: displayPrices
        ? formatPrice(getPriceIncludingChildren(lot, "price_vat_ex", wrapper))
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_ex", wrapper)
        : undefined,
      return_rates: return_rates,
    };
  }

  /* LLI TMP refs #9179 */
  if (
    slice &&
    wrapper.program.field_is_lli &&
    wrapper.program.field_disp_grid_lli &&
    getPriceIncludingChildren(lot, "price_vat_ex", wrapper, true) > 0
  ) {
    data.lli = {
      data_test: "price-vat-inc-reduced",
      price_label: "LLI TVA 10 %",
      price: displayPrices
        ? formatPrice(
            getPriceIncludingChildren(lot, "price_vat_ex", wrapper, true) * 1.1,
          )
        : "Nous consulter",
      price_raw: displayPrices
        ? getPriceIncludingChildren(lot, "price_vat_ex", wrapper, true) * 1.1
        : undefined,
      return_rates: [],
    };
  }

  return data;
}

export function getCheapestPriceGroup(
  pricesGroup: PricesAndReturnRates,
  keys: PriceType[],
): PriceGroup | undefined {
  // Filter the object to include only the specified keys
  const filteredEntries = Object.entries(pricesGroup).filter(
    ([key, group]) =>
      keys.includes(key as PriceType) && group?.price_raw !== undefined,
  );

  if (filteredEntries.length === 0) return undefined;
  if (filteredEntries.length === 1) return filteredEntries[0][1];

  // Sort the filtered entries based on price_raw and key order
  const sortedEntries = filteredEntries.sort((a, b) => {
    const priceDiff = a[1]!.price_raw! - b[1]!.price_raw!;
    if (priceDiff !== 0) return priceDiff; // Sort by price if different
    return keys.indexOf(a[0] as PriceType) - keys.indexOf(b[0] as PriceType); // Sort by key order if prices are the same
  });

  // Return the cheapest entry (first item in the sorted array)
  return sortedEntries.length > 0 ? sortedEntries[0][1] : undefined;
}

type LotType = "parking" | "cellar" | "basement";

export function getChildrenOfType(lot: Lot, type: LotType, allLots: Lot[]) {
  const settings = useDrupalSettingsContext();

  return allLots.filter((l) => {
    switch (type) {
      case "parking":
        return (
          l.lot_parent_id === lot.lot_id &&
          l.re_core_entities_lot_type.drupal_internal__tid ===
            settings.lot_types.parking
        );
      case "cellar":
        return (
          l.lot_parent_id === lot.lot_id &&
          l.re_core_entities_lot_type.drupal_internal__tid ===
            settings.lot_types.cellier
        );
      case "basement":
        return (
          l.lot_parent_id === lot.lot_id &&
          l.re_core_entities_lot_type.drupal_internal__tid ===
            settings.lot_types.cave
        );
    }
  });
}

/**
 * Gets all children of a lot.

 * @param lot Lot
 * @param allLots Lot[]
 * @returns Lot[]
 */
export function getChildren(lot: Lot, allLots: Lot[]) {
  return allLots.filter((l) => {
    return l.lot_parent_id === lot.lot_id;
  });
}

/**
 * Formats the plus for the lot as a comma separated list of features.
 *
 * @param wrapper ProgramWrapper
 * @param lot Lot
 * @returns string
 */
export function getPlusForTypology(wrapper: ProgramWrapper, lot: Lot) {
  const plusArray: string[] = [];

  getChildrenOfType(lot, "parking", wrapper.lots).length > 0 &&
    plusArray.push("parking(s)");

  getChildrenOfType(lot, "cellar", wrapper.lots).length > 0 &&
    plusArray.push("cellier(s)");

  getChildrenOfType(lot, "basement", wrapper.lots).length > 0 &&
    plusArray.push("cave(s)");

  lot.garden && plusArray.push("jardin");
  lot.balcony && plusArray.push("balcon");
  lot.loggia && plusArray.push("loggia");
  lot.terrace && plusArray.push("terrasse");

  const plus = plusArray.join(", ").toLocaleLowerCase();
  return plus.slice(0, 1).toUpperCase() + plus.slice(1);
}

/**
 * Formats the plus for the lot Drawer as a comma separated list of features with surface.
 *
 * @param lot Lot
 * @returns string
 */
export function getPlusStr(lot: Lot) {
  const arr: string[] = [];
  const f = formatSurface;

  lot.garden && arr.push("jardin de " + f(lot.garden, 0, 2));
  lot.balcony && arr.push("balcon de " + f(lot.balcony, 0, 2));
  lot.loggia && arr.push("loggia de " + f(lot.loggia, 0, 2));
  lot.terrace && arr.push("terrasse de " + f(lot.terrace, 0, 2));

  const plus = arr.join(", ").toLocaleLowerCase();
  return plus.slice(0, 1).toUpperCase() + plus.slice(1);
}

export function getCheapestPrice(lot: Lot, fields: PriceField[]): number {
  let price = Number.MAX_VALUE;
  for (const field of fields) {
    if (lot[field] && lot[field]! < price) {
      price = lot[field]!;
    }
  }
  return price;
}

/**
 * Formats the outdoors for the lot as a comma separated list of features.
 *
 * @param wrapper ProgramWrapper
 * @param lot Lot
 * @returns string
 */
export function getOutdoorsForTypology(wrapper: ProgramWrapper, lot: Lot) {
  const outdoorsArray: string[] = [];

  lot.garden && outdoorsArray.push("jardin");
  lot.balcony && outdoorsArray.push("balcon");
  lot.loggia && outdoorsArray.push("loggia");
  lot.terrace && outdoorsArray.push("terrasse");

  const outdoors = outdoorsArray.join(", ").toLocaleLowerCase();
  return outdoors.slice(0, 1).toUpperCase() + outdoors.slice(1);
}

export function lotHasPriceBelow(
  lot: Lot,
  price: number,
  fields: PriceField[],
  wrapper: ProgramWrapper,
): boolean {
  for (const field of fields) {
    if (lot[field]) {
      const p = getPriceIncludingChildren(lot, field, wrapper);
      if (p <= price && p !== 0) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Returns the price including the children prices.
 * @param lot Lot
 * @param field PriceField
 * @param allLots Lot[]
 * @returns number
 */
export function getPriceIncludingChildren(
  lot: Lot,
  field: PriceField,
  wrapper: ProgramWrapper,
  requestingLLI: boolean = false,
): number {
  if (!lot[field]) return 0;

  const slicePriceFields = getPricesFieldNamesForRegulationTids(
    wrapper.slices.find(
      (s) =>
        s.drupal_internal__id === lot.slice_id.meta.drupal_internal__target_id,
    )!,
  );

  // If the lot has no parent, take into account for the slice price fields based on the regulation
  if (!lot.lot_parent_id) {
    if (
      !slicePriceFields.includes(field) &&
      field !== "price_furniture_vat_ex" &&
      !requestingLLI
    )
      return 0;
  }

  const scaleFactor = 100;
  let price = Math.round(lot[field] * scaleFactor);
  for (const child of getChildren(lot, wrapper.lots)) {
    if (child.published) {
      const childPrice = getPriceIncludingChildren(child, field, wrapper) || 0;
      price += Math.round(childPrice * scaleFactor);
    }
  }

  return price / scaleFactor;
}
